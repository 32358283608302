import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from "../config";

export default function Datebtn(props) {
  const today = new Date();
  const minDate = today.toISOString().split('T')[0];

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    props.state.userData.selectedDate = selectedDate;
    const fetchData = async () => {
      try {
          const response = await axios.get( `${config.apiUrl}/availableTimeSlots`,{
            params: { selectedDate:selectedDate }
        });

        setBookedSlots(response.data);
      } catch (error) {
        console.error('Error fetching booked slots:', error);
      }
    };

    fetchData();
  };

  const {selectedDate} = props.state.userData
   console.log('selectedDate',selectedDate);
    const [bookedSlots, setBookedSlots] = useState([]);
    console.log('bookedslots',bookedSlots);

 
  
    const handletimeChange = (event) => {
        const selectedTime = event.target.value;
        props.state.userData.selectedTime = selectedTime
        if (selectedTime) {
          props.actions.medicalAffiliationaction(selectedTime);
        }
      };

  return (
    <div style={{width:'250px'}} className='container'>
      <div className='row mb-2' style={{fontSize:'12px'}}>
        <div className='col-md-12'>
      <input type="date" id="appointmentdate" onChange={handleDateChange} min={minDate} />
      </div>
      </div>
      <div className='row mb-2' style={{fontSize:'13px'}}>
        <div className='col-md-12'>
            <select onChange = {handletimeChange}>
                <option value="">Select a time</option>
                {bookedSlots.map((slot, index) => (
                    <option key={index} value={slot}>
                        {slot}
                    </option>
                ))}
            </select>
        </div>
        </div>
    </div>
  );
}