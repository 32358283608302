import React, { useState, useEffect } from "react";
import "./style.css";
import home from "../src/chatbot/home 1.png";
import img1 from "../src/chatbot/1.png";
import whitening from "../src/chatbot/whitening.gif";
import img2 from '../src/chatbot/2.png';
import cleaning from "../src/chatbot/cleaning.gif";
import logo from '../src/chatbot/logo - Copy.jpeg';
import img3 from '../src/chatbot/3.png';
import flows from "../src/chatbot/flows.gif";
import img4 from '../src/chatbot/4.png';
import cavity from "../src/chatbot/cavity.gif";
import img5 from '../src/chatbot/5.png';
import braces from "../src/chatbot/braces.gif";
import ChatApp from "./chatbotAction/chatApp";
import tab  from './chatbot/tabhome.png';
import mob from './chatbot/mobhome.png'
import close from './chatbot/close.png'

function DupPage() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedContent, setSelectedContent] = useState(null);
 

 
 



  const handleImageClick = (imageSrc, content) => {
    setSelectedImage(imageSrc);
    setSelectedContent(content);
    setIsModalOpen(true);
   
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
    setSelectedContent(null);
  };

  const handleHoverBlur = () => {
    const elements = document.querySelectorAll('.overlap-group > *:not(:hover)');
    elements.forEach(element => {
      element.classList.add('blur');
    });
  };

  const handleHoverLeave = () => {
    const elements = document.querySelectorAll('.overlap-group > *:not(:hover)');
    document.querySelector('.home').classList.remove('blur');
    elements.forEach(element => {
      element.classList.remove('blur');
    });
  };

  return (
    <>
    
    <div className="group">
      <div className="overlap-group">
        <img
          className="home"
          alt="Home"
          src={home}
        />
        <div className="text-wrapper">CLEANING</div>
        <img className="logo-copy" alt="Logo copy" src={logo} />
        <img
          className="img"
          alt="Ellipse"
          src={img1}
          onTouchStart={() => { handleImageClick(whitening, "Let your smile sparkle and shine with teeth whitening – because confidence begins with a bright, white smile."); handleHoverBlur(); }}
          onMouseEnter={() => { handleImageClick(whitening, "Let your smile sparkle and shine with teeth whitening – because confidence begins with a bright, white smile."); handleHoverBlur(); }}
          onMouseLeave={() => { handleCloseModal(); handleHoverLeave(); }}
        />
        <img
          className="ellipse-2"
          alt="Ellipse"
          src={img2}
          onTouchStart={() => { handleImageClick(cleaning, "Restore the natural brilliance of your smile with the gentle touch of professional teeth cleaning. Let your confidence shine through every grin!"); handleHoverBlur(); }}
          onMouseEnter={() => { handleImageClick(cleaning, "Restore the natural brilliance of your smile with the gentle touch of professional teeth cleaning. Let your confidence shine through every grin!"); handleHoverBlur(); }}
          onMouseLeave={() => { handleCloseModal(); handleHoverLeave(); }}
        />
        <img
          className="ellipse-3"
          alt="Ellipse"
          src={img4}
          onTouchStart={() => { handleImageClick(cavity, "Banish the darkness, embrace the light. Fill those cavities, make your smile bright. Let joy overflow, from tooth to tooth. With every filling, renew your youth."); handleHoverBlur(); }}
          onMouseEnter={() => { handleImageClick(cavity, "Banish the darkness, embrace the light. Fill those cavities, make your smile bright. Let joy overflow, from tooth to tooth. With every filling, renew your youth."); handleHoverBlur(); }}
          onMouseLeave={() => { handleCloseModal(); handleHoverLeave(); }}
        />
        <img
          className="ellipse-4"
          alt="Ellipse"
          src={img5}
          onTouchStart={() => { handleImageClick(flows, "Embark on the gentle stream of dental flows, where every ripple unveils the radiant beauty of your smile, flowing towards a brighter tomorrow."); handleHoverBlur(); }}
          onMouseEnter={() => { handleImageClick(flows, "Embark on the gentle stream of dental flows, where every ripple unveils the radiant beauty of your smile, flowing towards a brighter tomorrow."); handleHoverBlur(); }}
          onMouseLeave={() => { handleCloseModal(); handleHoverLeave(); }}
        />
        <div className="div">WHITENING</div>
        <div className="text-wrapper-2">DENTAL FLOWS</div>
        <div className="text-wrapper-3">CAVITY FILLING</div>
        <div className="text-wrapper-4">BRACES</div>
        <img
          className="ellipse"
          alt="Ellipse"
          src={img3}
          onTouchStart={() => { handleImageClick(braces, "Braces: Crafting Confident Smiles! Join the journey of transformation, where every adjustment brings joy and assurance."); handleHoverBlur(); }}
          onMouseEnter={() => { handleImageClick(braces, "Braces: Crafting Confident Smiles! Join the journey of transformation, where every adjustment brings joy and assurance."); handleHoverBlur(); }}
          onMouseLeave={() => { handleCloseModal(); handleHoverLeave(); }}
        />
        <div className="text-wrapper-5">OCEAN DENTAL CLINIC</div>
      </div>
      <div className={`modal ${isModalOpen ? 'open' : ''}`}>
          <div className={`modal-content ${isModalOpen ? 'open' : ''}`}>
            {isModalOpen && (<div>
              
              <button className="close-btn " onClick={handleCloseModal}><img src={close} /></button>
            
              <div className="d-flex justify-content-between mt-2">
                <div>
                  <img src={selectedImage} alt="Selected" />
                </div >
                <div className="ms-1 d-flex justify-content-center align-items-center">
                  <p className="selectedcontent">{selectedContent}</p>
                  
                </div>
              </div>
              </div>
            )}
          </div>
        </div>
      
    </div>
    <ChatApp />
    </>
  );
}

export default DupPage;
