import React, { useState, useEffect } from 'react';

function Dob(props) {
  const [formData, setFormData] = useState({
    dob: ''
  });

  useEffect(() => {
    const today = new Date();
    const oneYearAgo = new Date(today);
    oneYearAgo.setFullYear(today.getFullYear() - 1);
    const formattedDate = `${oneYearAgo.getFullYear()}-${String(oneYearAgo.getMonth() + 1).padStart(2, '0')}-${String(oneYearAgo.getDate()).padStart(2, '0')}`;
    setFormData({ dob: formattedDate });
  }, []); // Run only once on component mount to set the default date one year ago

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Check if the selected date is fully specified (year, month, and day)
    if (value.match(/^\d{4}-\d{2}-\d{2}$/)) { // Date format: "YYYY-MM-DD"
      const birthDate = new Date(value);
      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      props.state.registerData.age = age;
      props.state.registerData.dob = value; // Update dob in registerData using the value from the input
      console.log('age',age);
      console.log('dob',value);
      props.actions.genderaction();
    } else {
      props.state.registerData.age = ''; // Clear age if the date is incomplete
      props.state.registerData.dob = ''; // Clear dob if the date is incomplete
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="mb-3 col-8">
          <input
            type="date"
            className="form-control"
            id="dob"
            name="dob"
            max={`${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-${String(new Date().getDate()).padStart(2, '0')}`}
            value={formData.dob}
            onChange={handleChange}
            required
          />
        </div>
      </div>
    </div>
  );
}

export default Dob;
