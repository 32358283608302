import React from 'react';

function Gender(props) {
    const handleGenderChange = (e) => {
        const selectedGender = e.target.value;
        if (selectedGender){
        props.state.registerData.gender = selectedGender;
        props.actions.numberRegistrationaction()
        } // Call the onChange handler with the selected gender
    };

    return (
        <div>
            <select value={props.value} onChange={handleGenderChange}>
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="transgender">Transgender</option>
                <option value="others">Others</option>
            </select>
        </div>
    );
}

export default Gender;
