import React from 'react';

export default function Doctorbtn (props) {

  const appointment = (doctorname) => {
    props.actions.appointment(doctorname);
  }

  return (
    <div style={{fontSize:'12px'}} className='container'>
      <button className='fdoc-btn rounded' onClick={() => appointment("Dr. P. Priyanka Angline B.D.S")} style={{backgroundColor:'rgb(72,194,205)', color:'white'}}>Dr.P.Priyanka Angline B.D.S</button>
      
    </div>
  );
}
