import './App.css';
import HomePage from './home';
import DupPage from './dup';

function App() {
  return (
    <div className="App">
      <DupPage/>
    </div>
  );
}

export default App;
