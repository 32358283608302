import React, { useState, useEffect } from 'react';
import successgif from '../gif/Hug.gif';

function Success() {
    const [zooming, setZooming] = useState(true);

    useEffect(() => {
        // Trigger zoom-in animation after a short delay
        const zoomTimer = setTimeout(() => {
            setZooming(false);
        }, 1000); // Adjust the delay as needed

        return () => clearTimeout(zoomTimer);
    }, []);

    return (
        <div className={zooming ? 'zoom-in' : ''}>
            <img
                src={successgif}
                alt="Success GIF"
                style={{
                    height: '50px',
                    width: '50px',
                }}
            />
            <style>
                {`
                    .zoom-in {
                        animation: zoomIn 1s forwards;
                    }

                    @keyframes zoomIn {
                        0% {
                            transform: scale(0);
                        }
                        100% {
                            transform: scale(1);
                        }
                    }
                `}
            </style>
        </div>
    );
}

export default Success;
