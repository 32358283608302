import React from 'react';
import axios from 'axios';
import config from '../config';

const MessageParser = ({ children, actions }) => {

  const { checker } = children.props.state;

  const parse = (message) => {
    // if (checker === "register") {
    //   actions.askRegisterAction();
    // }
    if (checker === "doctor") {
      actions.yesAction();
    }
    if (checker === "date") {
      actions.appointment();
    }
    if (checker === "time") {
      actions.timeaction();
    }
    if (checker === "name") {
      actions.nameaction();
    }
    if (checker === "Dob") {
      actions.DobRegistration();
      children.props.setState(prevState => ({
        ...prevState,
        registerData: {
          ...prevState.registerData,
          fatherName: message
        }
      }));
     
    }
    if (checker === "numberRegister") {
      actions.numberRegistrationaction();
      children.props.setState(prevState => ({
        ...prevState,
        registerData: {
          ...prevState.registerData,
          age: message
        }
      }));
    }

    if (checker === "FinalRegister") {
      actions.FinalRegistrationaction();
    }
    if (checker === "gender") {
      actions.genderaction();
   
    } 
    if (checker === "Aadhar") {
      actions.AadharRegistrationaction();
      children.props.setState(prevState => ({
        ...prevState,
        registerData: {
          ...prevState.registerData,
          mobileNumber: message
        }
      }));
      
    }
    if (checker === "Address") {
      
      children.props.setState(prevState => ({
        ...prevState,
        registerData: {
          ...prevState.registerData,
          aadhaarNumber: message
        }
      }));
      const checkAadhaar = async() =>{
        try {
          const response = await axios.get(`${config.apiUrl}/patientAadhaar/${message}`);
          console.log('res',response);
          if(response.data.message === 'Aadhaar already exists'){
           actions.AlreadyExistaction(response.data.message);

          }else{
            actions.AddressRegistrationaction();
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }
      checkAadhaar();

    }
    if (checker === "FinalRegister") {
      
      children.props.setState(prevState => ({
        ...prevState,
        registerData: {
          ...prevState.registerData,
          homeAddress: message
        }
      }));
     
    }
     
    // if (checker === "age") {
    //   actions.ageaction();
     
    // }
    if (checker === "number") {
      actions.numberaction();
      children.props.setState(prevState => ({
        ...prevState,
        userData: {
          ...prevState.userData,
          age: message
        }
      }));
    }
    if (checker === 'uhid'){
       children.props.setState(prevState => ({
        ...prevState,
        userData: {
          ...prevState.userData,
          mobileNo: message
        }
      }));
      const checkMobileno = async() =>{
        try {
          const response = await axios.get(`${config.apiUrl}/patientMobile/${message}`);
          console.log('res',response);
          if(response.data.message === 'Not Registered'){
           actions.notRegisterMobile('Your Number is not Registered');
           if (actions.notRegisterMobile) {
            setTimeout(() => {
              actions.RegisterMobile();
             }, 1000);
           }
          
          

          }else{
            actions.Uhidselectaction();
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }
      checkMobileno();
      
      
    }
    if (checker === "medicalAffiliation") {
      actions.medicalAffiliationaction();
      // children.props.setState(prevState => ({
      //   ...prevState,
      //   userData: {
      //     ...prevState.userData,
      //     mobileNo: message
      //   }
      // }));
    }
    if (checker === "final") {
      actions.finalaction(
        children.props.state.userData.patientName,
        children.props.state.userData.doctorName,
        children.props.state.userData.selectedDate,
        children.props.state.userData.selectedTime
      );
      children.props.setState(prevState => ({
        ...prevState,
        userData: {
          ...prevState.userData,
          medicalAffiliation: message
        }
      }));
    }
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions,
        });
      })}
    </div>
  );
};

export default MessageParser;
