import React, { useState, useEffect } from 'react';
import welcome from '../gif/512.gif';

const WelcomeGifWidget = ({ gifPath}) => {
  const [jumping, setJumping] = useState(true); 
  


  useEffect(() => {
    console.log('gif', gifPath);
    // Toggle jumping back to false after a short delay
    const jumpTimer = setTimeout(() => {
      setJumping(false);
    }, 1000); // Adjust the delay as needed

    return () => clearTimeout(jumpTimer);
  }, [gifPath]); // Empty dependency array to run only once when component mounts

  // Render null if gifPath is undefined
  if (!gifPath) return null;

  return (
    <div>
      <img
        src={gifPath}
        alt="Welcome GIF"
        style={{
          height: '60px',
          width: '60px',
          animation: jumping ? 'jump 0.5s infinite alternate' : 'none',
        }}
      />
      <style>
        {`
          @keyframes jump {
            0% {
              transform: translateY(20px);
            }
            100% {
              transform: translateY(-20px);
            }
          }
        `}
      </style>
    </div>
  );
};

export default WelcomeGifWidget;
