import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';

function Confirm(props) {
    const [isLoading, setIsLoading] = useState(false);
    const formData = props.state.registerData;
    const [choiceMade, setChoiceMade] = useState(false);

  

    const handleYesClick = async () => {
        
        if(!choiceMade){
            setIsLoading(true);
        try {
            const response = await axios.post(`${config.apiUrl}/patientregistration`, formData);
            console.log('Response:', response.data);
            console.log('uhid:', response.data.uhid);
            if (response.data.message === "Data saved successfully"){
                console.log('Response:', response.data);
                props.state.userData.uhid = response.data.uhid;
                props.state.userData.gender = response.data.request.gender;
                props.state.userData.age = response.data.request.age;
                props.state.userData.mobileNo = response.data.request.mobileNumber;
                props.state.userData.patientName = `${response.data.request.title}.${response.data.request.firstName} ${response.data.request.lastName}`;
                setChoiceMade(true)
                props.actions.bookedRegistrationaction('Registration Success');
            setTimeout(() => {
                if(props.actions.bookedRegistrationaction){
                    props.actions.yesAction();
                }
            }, 2000);
            }
            // Handle the response data here
        } catch (error) {
            console.error('Error:', error);
            if (error.response.data === 'Aadhaar number already exists'){
            props.actions.bookedRegistrationaction('Aadhaar Number already registered');
            }
        }finally {
            setIsLoading(false);
 

        }
    }
    };

    const handleNoClick = () => {
        if (!choiceMade) {
            setChoiceMade(true)
            props.actions.noAction();
        }
    };

    return (
        <div>
           <table className="table table-responsive text-start " style={{fontSize:'12px'}}>
                <tbody>
                    <tr>
                        <td>Name</td>
                        <td>{formData.title} {formData.firstName} {formData.lastName}</td>
                    </tr>
                    <tr>
                        <td>Age</td>
                        <td>{formData.age}</td>
                    </tr>
                    <tr>
                        <td>Gender</td>
                        <td>{formData.gender}</td>
                    </tr>
                    <tr>
                        <td>DOB</td>
                        <td>{formData.dob}</td>
                    </tr>
                    <tr>
                        <td>Mobile No</td>
                        <td>{formData.mobileNumber}</td>
                    </tr>
                    <tr>
                        <td>Aadhaar No</td>
                        <td>{formData.aadhaarNumber}</td>
                    </tr>
                    <tr>
                        <td>Address</td>
                        <td>{formData.homeAddress}</td>
                    </tr>
                </tbody>
            </table>
            <div>
                <button onClick={handleYesClick} className='me-2 rounded' style={{backgroundColor:'rgb(72,194,205)', color:'white'}} disabled={isLoading}>
                    {isLoading ? 'Loading...' : 'Yes'}
                </button>
                <button onClick={handleNoClick} className='me-2 rounded' style={{backgroundColor:'rgb(72,194,205)', color:'white'}} disabled={isLoading}>No</button>
            </div>
        </div>
    );
}

export default Confirm;
