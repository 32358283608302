import { createChatBotMessage } from 'react-chatbot-kit';
import WelcomeGifWidget from './WelcomeGifWidget'; // Import the WelcomeGifWidget component
import AppointBtn from '../chatbot/appointBtn';
import Doctorbtn from '../chatbot/doctorbtn';
import Datebtn from '../chatbot/datebtn';
import TimeBtn from '../chatbot/timebtn';
import Finalbtn from '../chatbot/finalbtn';
import Regiter from '../chatbot/RegisterBTn';
import Uhid from '../chatbot/Uhid';
import Name from '../chatbotRegistration/Name';
import Dob from '../chatbotRegistration/Dob';
import Confirm from '../chatbotRegistration/Confirm';
import Gender from '../chatbotRegistration/Gender';
import welcomeGif from '../gif/chick.gif'; // Import the GIF file
import Success from './Success';
import ThumbGif from './ThumbGifs'




const config = {
  initialMessages: [
    createChatBotMessage("Hi,  Welcome to Ocean Dental Clinic!", {
      widget: "WelcomeGifWidget"}),
    
    createChatBotMessage(`Do you need Doctor Appointment ?`, {
      widget: "Register",
    }),
  ],
  botName: 'Ocean Dental Clinic',
  customStyles: {
    botMessageBox: {
      backgroundColor: '#376B7E',
    },
    chatButton: {
      backgroundColor: '#5ccc9d',
    },
  },
  state: {
    checker: null,
    userData: {
      uhid: '',
      doctorName: "",
      selectedDate: "",
      selectedTime: "",
      patientName: "",
      gender: '',
      age: 0,
      mobileNo: "",
      medicalAffiliation: ''
    },
    registerData: {
      title: '',
      firstName: '',
      lastName: '',
      fatherName: '',
      dob: '',
      age: '',
      gender: '',
      mobileNumber: '',
      aadhaarNumber: '',
      homeAddress: '',
    }
  },
  widgets: [
    {
      widgetName: "WelcomeGifWidget",
      widgetFunc: (props) => <WelcomeGifWidget gifPath={welcomeGif} {...props} />, // Pass the gifPath prop
    },    
    {
      widgetName: "Register",
      widgetFunc: (props) => <Regiter {...props} />
    },
    {
      widgetName: "Uhid",
      widgetFunc: (props) => <Uhid {...props} />
    },
    {
      widgetName: "YesorNo",
      widgetFunc: (props) => <AppointBtn {...props} />
    },
    {
      widgetName: "Doctor",
      widgetFunc: (props) => <Doctorbtn {...props} />
    },
    {
      widgetName: "Date",
      widgetFunc: (props) => <Datebtn {...props} />
    },
    {
      widgetName: "Time",
      widgetFunc: (props) => <TimeBtn {...props} />
    },
    {
      widgetName: "Final",
      widgetFunc: (props) => <Finalbtn {...props} />
    },
    {
      widgetName: "RegisterName",
      widgetFunc: (props) => <Name {...props} />
    },
    {
      widgetName: "DOB",
      widgetFunc: (props) => <Dob {...props} />
    },
    {
      widgetName: "ConfirmRegister",
      widgetFunc: (props) => <Confirm {...props} />
    },
    {
      widgetName: "Gender",
      widgetFunc: (props) => <Gender {...props} />
    },
    {
      widgetName: "SuccessGifWidget",
      widgetFunc: (props) => <Success  {...props} />, // Pass the gifPath prop
    },
    {
      widgetName: "ThumbGif",
      widgetFunc: (props) => <ThumbGif {...props} />
    },
  ],
};

export default config;
