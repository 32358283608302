import React, { useState, useEffect, useRef, forwardRef } from "react";
import Chatbot from "react-chatbot-kit";
import 'react-chatbot-kit/build/main.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCommentAlt } from '@fortawesome/free-solid-svg-icons'
import config from "./Config";
import MessageParser from "./MessageParser";
import ActionProvider from "./Actionprovider";
import logo from '../chatbot/logo - Copy.jpeg'

const ChatApp = forwardRef((props, ref) => {
  const bgimage = `url('${logo}')`
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [shouldRenderChatbot, setShouldRenderChatbot] = useState(false);
  const [key, setKey] = useState(0); // State to force Chatbot to re-render

  const chatbotRef = useRef(null); // Reference to chatbot component

  useEffect(() => {
    if (dropdownOpen) {
      setShouldRenderChatbot(true);
      if (chatbotRef.current) {
        chatbotRef.current.restartConversation(); // Restart chatbot's conversation
      }
    } else {
      setShouldRenderChatbot(false);
    }
  }, [dropdownOpen]);

 
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
    setKey(key + 1);
    if (dropdownOpen) {
      const chat = document.getElementById('chat');
      if (chat) {
        chat.style.display = 'block'; // Show the chat
      }
    } else {
      const chat = document.getElementById('chat');
      if (chat) {
        chat.style.display = 'none'; // Hide the chat
      }
    } // Increment key to force Chatbot to re-render
  };

  const validator = (input) => {
    return input.trim().length > 0;
  };

  return (
    <>
    <style>
      {
        `

        .react-chatbot-kit-chat-header {
          font-size: 0;
        }
        
        .react-chatbot-kit-chat-bot-avatar-letter{
         font-size:0;
        }
        
        .react-chatbot-kit-chat-bot-avatar-container {
          background-image: ${bgimage};
          background-size: cover; /* Adjust as needed */
          border-radius: 50%; /* Apply border-radius to make it circular */
        }
         
        
        .react-chatbot-kit-chat-bot-avatar-letter::after{
          content:'';
         
          color:transparent;
        
        }
        
        .react-chatbot-kit-chat-message-container {
          padding: 0 17.5px 10px 17.5px;
          overflow-y:auto;
          overflow-x:hidden;
          height: 410px;
      }
        .react-chatbot-kit-chat-header::after {
          content: 'Ocean Dental Clinic';
          font-size: 16px; /* Set the appropriate font size */
        }
        .react-chatbot-kit-chat-container {
          
          height: 500px;
        }
        
        .react-chatbot-kit-chat-inner-container {
          height: 500px;
        }

        .chatbot-icon {
          position: fixed;
          bottom: 10px;
          left: 20px;
          background-color: #007bff;
          color: white;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
        }
        
        .chatbot-icon:hover {
          transform: scale(1.1);
        }
        
        .chatbot-container {
          position: fixed;
          bottom: 70px;
          left: 20px;
          width: 320px;
        }

        @media screen and (max-width: 800px) {
          .react-chatbot-kit-chat-message-container{
            height:65vh;
          }
          .chatbot-icon {
            left: 10px;
          }
          .react-chatbot-kit-chat-container {
            
            font-size:13px;
            height: 80vh;
          }
          
          .react-chatbot-kit-chat-inner-container {
            
            height: 80vh;
          }
          
         
        }

        @media screen and (max-width: 480px) {
          .chatbot-icon {
            bottom: 10px;
            left: 10px;
          }
          .react-chatbot-kit-chat-container {
            font-size:12px;
            height: 80vh;
            width: 80vw;
          
          .react-chatbot-kit-chat-inner-container {
            
            height: 80vh;
            width: 80vw;
          }
          
          .chatbot-container {
            position: fixed;
            bottom: 15vh;
            left: 20px;
            width: 80vw;
            height:80vh;
          }
        }
        `
      }
    </style>
    <div className="App" style={{ position: "relative" }} key={key} >
      <div>
        <div onClick={toggleDropdown} className="chatbot-icon">
          <FontAwesomeIcon icon={faCommentAlt} />
        </div>
        {shouldRenderChatbot && (
          <div className="chatbot-container " id="chat">
            <Chatbot
              ref={ref} // Assign ref to chatbot component
              config={config}
              messageParser={MessageParser}
              actionProvider={ActionProvider}
              validator={validator}
              // Key to force Chatbot to re-render
            />
          </div>
        )}
      </div>
    </div>
    </>
  );
});

export default ChatApp;
