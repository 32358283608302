import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from "../config";


export default function TimeBtn(props) {
   const {selectedDate} = props.state.userData
   console.log('selectedDate',selectedDate);
    const [bookedSlots, setBookedSlots] = useState([]);
    console.log('bookedslots',bookedSlots);

    useEffect(() => {
      const fetchData = async () => {
        try {
            const response = await axios.get( `${config.apiUrl}/availableTimeSlots`,{
              params: { selectedDate:selectedDate }
          });

          setBookedSlots(response.data);
        } catch (error) {
          console.error('Error fetching booked slots:', error);
        }
      };
  
      fetchData();
    }, []); 
  
    const handletimeChange = (event) => {
        const selectedTime = event.target.value;
        props.state.userData.selectedTime = selectedTime
        if (selectedTime) {
          props.actions.medicalAffiliationaction(selectedTime);
        }
      };

    return (
        <div>
            <select onChange = {handletimeChange}>
                <option value="">Select a time</option>
                {bookedSlots.map((slot, index) => (
                    <option key={index} value={slot}>
                        {slot}
                    </option>
                ))}
            </select>
        </div>
    )
}

