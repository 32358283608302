import React, { useState } from 'react';

export default function Regiter(props) {
  const [choiceMade, setChoiceMade] = useState(false);

  const yesAction = () => {
    if (!choiceMade) {
      props.actions.askRegisterAction();
      setChoiceMade(true);
    }
  }

  const noAction = () => {
    if (!choiceMade) {
      props.actions.noAction();
      setChoiceMade(true);
    }
  }

  return (
    <div style={{fontSize:'12px'}}>
      <button className='yes-btn me-2 rounded'style={{backgroundColor:'rgb(72,194,205)', color:'white'}} onClick={yesAction} 
      >
        Yes
      </button>
      <button className='no-btn rounded' style={{backgroundColor:'rgb(72,194,205)', color:'white'}} onClick={noAction} >
        No
      </button>
    </div>
  );
}

