import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from "../config";


export default function Uhid(props) {
   const {mobileNo} = props.state.userData
    const [uhidData, setUhidData] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
        try {
            const response = await axios.get( `${config.apiUrl}/patientregistration/mobile/${mobileNo}`);

            setUhidData(response.data);
            console.log('response',response.data);
        } catch (error) {
          console.error('Error fetching booked slots:', error);
        }
      };
  
      fetchData();
    }, []); 
  
    const handletimeChange = (event) => {
        const selectedUhidData = JSON.parse(event.target.value); 
        if (selectedUhidData) {
            console.log(selectedUhidData);
            // Update userData props
            props.state.userData.uhid = selectedUhidData.uhid;
            props.state.userData.mobileNo = selectedUhidData.mobileNumber;
            props.state.userData.patientName =  `${selectedUhidData.firstName} ${selectedUhidData.lastName}`;
            props.state.userData.gender = selectedUhidData.gender;
            props.state.userData.age = selectedUhidData.age;
            props.actions.yesAction(); 
        }
    };   

    return (
        <div className='container'>
            <select onChange = {handletimeChange} style={{fontSize:'12px'}}>
                <option value="">Select Uhid</option>
                {uhidData.map((uhidData, index) => (
                    <option key={index} value={JSON.stringify(uhidData)}>
                        {`${uhidData.uhid}-${uhidData.firstName} ${uhidData.lastName}`}
                    </option>
                ))}
            </select>
        </div>
    )
}

