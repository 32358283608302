import React, { useState } from 'react';
import config from "../config";
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Finalbtn(props) {
  const [choiceMade, setChoiceMade] = useState(false);

  const handleYesClick = async () => {
    if (!choiceMade) {
      try {
        const response = await axios.post(`${config.apiUrl}/Appointment`, props.state.userData);
        if (response.status === 200) {
          thankyouAction();
          console.log('Appointment booked successfully!');
        } else {
          console.error('Failed to book appointment');
        }
      } catch (error) {
        console.error('Error submitting form:', error);
      }
      setChoiceMade(true);
    }
  };
  const handleNoClick = () => {
    if (!choiceMade) {
      props.actions.noAction();
      setChoiceMade(true);
    }
  }

  const thankyouAction = () => {
    if (!choiceMade) {
      props.actions.thankyouAction();
      setChoiceMade(true);
    }
  }

  return (
    <>
    {/* <table>
      <tbody>
        <tr>
          <td>Doctor Name</td>
          <td>{props.state.userData.doctorname}</td>
        </tr>
        <tr>
          <td>Date</td>
          <td>{props.state.userData.selectedDate}</td>
        </tr>
        <tr>
          <td>Time</td>
          <td>{props.state.userData.selectedTime}</td>
        </tr>
      </tbody>
    </table> */}
        <div style={{fontSize:'12px'}}>
      <button className='me-2 rounded' style={{backgroundColor:'rgb(72,194,205)', color:'white'}} onClick={handleYesClick}>Yes</button>
      <button className='rounded' style={{backgroundColor:'rgb(72,194,205)', color:'white'}} onClick={handleNoClick}>No</button>
    </div>
    </>
  );
}

// try {
      //   const response = await fetch( `${config.apiUrl}/available-timings/book`, {
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //     body: JSON.stringify({
      //       doctor: doctorname,
      //       date: selectedDate,
      //       time: selectedTime,
      //       name: username,
      //       age,
      //       mobile: mobileno,
      //     }),
      //   });

      //   if (response.ok) {
      //     thankyouAction();
      //     console.log('Appointment booked successfully!');
      //   } else {
      //     console.error('Failed to book appointment');
      //   }
      // } catch (error) {
      //   console.error('Error:', error);
      // }