import React, { useState } from 'react';

function Name(props) {
    const [title, setTitle] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const [isTitleHighlighted, setIsTitleHighlighted] = useState(false);
    const [isFirstNameHighlighted, setIsFirstNameHighlighted] = useState(false);
    const [isLastNameHighlighted, setIsLastNameHighlighted] = useState(false);

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
        setIsTitleHighlighted(false);
    };

    const handleFirstNameChange = (e) => {
        const value = e.target.value;
        // Allow only alphabetic characters and spaces
        if (/^[A-Za-z\s]*$/.test(value) || value === '') {
            setFirstName(value);
            setIsFirstNameHighlighted(false);
        }
    };

    const handleLastNameChange = (e) => {
        const value = e.target.value;
        // Allow only alphabetic characters and spaces
        if (/^[A-Za-z\s]*$/.test(value) || value === '') {
            setLastName(value);
            setIsLastNameHighlighted(false);
        }
    };

    const handleNext = () => {
        // Check if any input is empty
        if (!title) {
            setIsTitleHighlighted(true);
        }
        if (!firstName) {
            setIsFirstNameHighlighted(true);
        }
        if (!lastName) {
            setIsLastNameHighlighted(true);
        }

        // Check if all inputs are filled
        if (title && firstName && lastName) {
            props.actions.FatherNameRegistration();
            props.state.registerData.title = title;
            props.state.registerData.firstName = firstName;
            props.state.registerData.lastName=lastName;
        }
    };

    return (
        <div className="container" style={{ width: "250px" }}>
            <style>
                {`
                .custom-input {
                    font-size: 12px;
                }
                .highlighted {
                    border-color: red;
                }
                `}
            </style>
            <div className="row mb-2">
                <div className="col-md-12">
                    <select
                        id="title"
                        className={`form-select custom-input ${isTitleHighlighted ? 'highlighted' : ''}`}
                        value={title}
                        onChange={handleTitleChange}
                    >
                        <option value="">select</option>
                        <option value="Mr">Mr</option>
                        <option value="Mrs">Mrs</option>
                        <option value="Miss">Miss</option>
                    </select>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-md-12">
                    <input
                        type="text"
                        id="firstName"
                        className={`form-control custom-input ${isFirstNameHighlighted ? 'highlighted' : ''}`}
                        placeholder='First Name'
                        value={firstName}
                        onChange={handleFirstNameChange}
                    />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-md-12">
                    <input
                        type="text"
                        id="lastName"
                        className={`form-control custom-input ${isLastNameHighlighted ? 'highlighted' : ''}`}
                        placeholder='Last Name'
                        value={lastName}
                        onChange={handleLastNameChange}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 text-end">
                    <button className='me-2 rounded' style={{backgroundColor:'rgb(72,194,205)', color:'white'}}onClick={handleNext}>Next</button>
                </div>
            </div>
        </div>
    );
}

export default Name;
