import React, { useState, useEffect } from 'react';
import success from '../gif/Hug.gif';
const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const [executedActions, setExecutedActions] = useState({
    doctor: false,
    date: false,
    time: false,
    name: false,
    age: false,
    number: false,
    no: false,
    thankyou: false,
    medical: false,
    fathername: false,
    Dob:false,
    gender:false,
    numberRegister:false,
    Aadhar:false,
    Address:false,
    finalRegister:false,

  });

  const [currentAction, setCurrentAction] = useState(null);

  useEffect(() => {
    const chatInputContainer = document.querySelector('.react-chatbot-kit-chat-input-container');
    const buttonElement = chatInputContainer.querySelector("button")
 
    if (!chatInputContainer) {
        console.error('Chat input container not found');
        return;
    }

    const inputElement = chatInputContainer.querySelector('input');
    if (!inputElement) {
        console.error('Input element not found');
        return;
    }

    const handleKeyDown = (e) => {
      const value = e.target.value;
  
      if (currentAction === 'name' || currentAction === 'Fathername') {
          if (!/^[a-zA-Z .]*$/.test(e.key) && e.key !== 'Backspace') {
              e.preventDefault();
          }
      } else if (currentAction === 'age') {
          if (!/^[0-9]*$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Enter') {
            e.preventDefault();
        }
        if (value.length >= 2 && e.key !== 'Backspace' && e.key !== 'Enter') {
            e.preventDefault();
        }
      } else if (currentAction === 'number' || currentAction === 'numberRegister' || currentAction === 'Notnumber' ) {
       
        if (!/^[0-9]*$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Enter') {
            e.preventDefault();
        }
        if (value.length >= 10 && e.key !== 'Backspace' && e.key !== 'Enter') {
            e.preventDefault();
        }
        if (e.key === 'Enter' && value.length < 10) {
            e.preventDefault();
        }
      

        const sendButton = chatInputContainer.querySelector("button");
        if (sendButton) {
          if (value.length < 10) {
            sendButton.disabled = true; 
          } else {
            sendButton.disabled = false; 
          }
        }
      
    } else if (currentAction === 'Aadhar') {
       
      if (!/^[0-9]*$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Enter') {
          e.preventDefault();
      }
      if (value.length >= 12 && e.key !== 'Backspace' && e.key !== 'Enter') {
          e.preventDefault();
      }
      if (e.key === 'Enter' && value.length < 12) {
          e.preventDefault();
      }
    

      const sendButton = chatInputContainer.querySelector("button");
      if (sendButton) {
        if (value.length < 10) {
          sendButton.disabled = true; 
        } else {
          sendButton.disabled = false; 
        }
      }
    
  }
  };
  
    inputElement.addEventListener('keydown', handleKeyDown);

    return () => {
        inputElement.removeEventListener('keydown', handleKeyDown);
    };

}, [currentAction]);

  const disableChatInput = () => {
    const chatInputContainer = document.getElementsByClassName('react-chatbot-kit-chat-input-container')[0];
    if (chatInputContainer) {
      const inputElement = chatInputContainer.querySelector('input');
      const buttonElement = chatInputContainer.querySelector('button');
      if (inputElement) {
        inputElement.disabled = true;
        buttonElement.disabled = true;
      }
    }
  };
 
  const enableChatInput = () => {
    const chatInputContainer = document.getElementsByClassName('react-chatbot-kit-chat-input-container')[0];
    if (chatInputContainer) {
      const inputElement = chatInputContainer.querySelector('input');
      const buttonElement = chatInputContainer.querySelector('button');
      if (inputElement) {
        inputElement.disabled = false;
        buttonElement.disabled = false;
      }
    }
  };

  const updateExecutedActions = (action) => {
    setExecutedActions((prevActions) => ({
      ...prevActions,
      [action]: true,
    }));
  };

  const initialAction = () => {
    if (!executedActions.doctor) {
      const message = createChatBotMessage('Do you need a Doctor Appointment ?', {
        widget: 'Register',
      });
      updateState(message, 'register');
      updateExecutedActions('register');
    }
    disableChatInput()
  };
  const askRegisterAction = () => {
    if (!executedActions.doctor) {
      const message = createChatBotMessage('Have you already registered ?', {
        widget: 'YesorNo',
      });
      updateState(message, 'number');
      updateExecutedActions('number');
    }
    disableChatInput()
  };

  // if click No then Registration

  const NameRegistration = ()=>{
    if(!executedActions.fathername){
    const message = createChatBotMessage('Your Name ?', {
      widget:'RegisterName'
    });
    updateState(message,'Fathername');
    disableChatInput();
    updateExecutedActions('fathername')
  }
  }
  const FatherNameRegistration = ()=>{
    if(!executedActions.Dob){
    setCurrentAction('Fathername');
    const message = createChatBotMessage('Father Name ?', {});
    updateState(message, 'Dob');
    enableChatInput();
    updateExecutedActions('Dob');
    }
  }

  const DobRegistration = ()=>{
    if(!executedActions.gender){
    const message = createChatBotMessage('Date Of Birth ?', {
      widget:'DOB'
    });
    updateState(message, 'gender');
    updateExecutedActions('gender');
    disableChatInput();
  }
  }

  const genderaction = () => {
    if(!executedActions.numberRegister){
    setCurrentAction('gender');
    const message = createChatBotMessage('Gender ?',{
      widget:'Gender'
    });
    updateState(message, 'numberRegister');
    updateExecutedActions('numberRegister');
    disableChatInput();
  }
 
};

// const ageaction = () => {
//   if(!executedActions.numberRegister){
//   setCurrentAction('age');
//   const message = createChatBotMessage('Your Age ?');
//   updateState(message, 'numberRegister');
//   updateExecutedActions('numberRegister');
//   enableChatInput();
//   }
// };

const numberRegistrationaction = () => {
  if(!executedActions.Aadhar){
  setCurrentAction('numberRegister');
  const message = createChatBotMessage('Your Mobile Number ?', {});
  updateState(message, 'Aadhar');
  updateExecutedActions('Aadhar');
  enableChatInput();
  }

};
const AadharRegistrationaction = () => {
  if(!executedActions.Address){
  setCurrentAction('Aadhar');
  const message = createChatBotMessage('Your Aadhar Number ?', {});
  updateState(message, 'Address');
  updateExecutedActions('Address');
  enableChatInput();
  }

};

// Aadhaar already exist

const AlreadyExistaction = (mess) =>{
  setCurrentAction('Aadhar');
  const message = createChatBotMessage(`${mess}`, {});
  updateState(message, 'Address');
  updateExecutedActions('Address');
  enableChatInput();
}


const AddressRegistrationaction = () => {
  if(!executedActions.finalRegister){
  setCurrentAction('Address');
  const message = createChatBotMessage('Your Address ?', {});
  updateState(message, 'FinalRegister');
  updateExecutedActions('finalRegister');
  enableChatInput();
  }

};


const FinalRegistrationaction = () => {
  if(!executedActions.doctorName){
  const message = createChatBotMessage('confirm your Registration?', {
    widget:'ConfirmRegister'
  });
  updateState(message);
  disableChatInput();
  updateExecutedActions('doctorName');
}

};
const bookedRegistrationaction = (bookedmsg) => {
 
  const message = createChatBotMessage( `${bookedmsg}`, {
    widget:'SuccessGifWidget'
  });
  updateState(message);
  disableChatInput();
  updateExecutedActions('doctorName');


};

// if click yes ask register number

  const numberaction = () => {
    setCurrentAction('number');
    const message = createChatBotMessage('Your Registered Mobile Number ?', {});
    updateState(message, 'uhid');
    enableChatInput();

  };

  const notRegisterMobile = (givenmob) =>{
    setCurrentAction('Notnumber');
    const message = createChatBotMessage(`${givenmob}`, {});
    updateState(message, 'uhid');
    enableChatInput();
  }
 
  const RegisterMobile = () =>{
    setCurrentAction('Notnumber');
    const message = createChatBotMessage('Enter Registered Mobile No', {});
    updateState(message, 'uhid');
    enableChatInput();
  }

  const Uhidselectaction = () => {
    const message = createChatBotMessage('Select your Uhid ?', {
      widget:'Uhid',
    });
    updateState(message, 'doctor');
    updateExecutedActions('doctor');
    disableChatInput()

  };

  // Appointment actions

  const yesAction = () => {
    if (!executedActions.date) {
      const message = createChatBotMessage('Available Doctors', {
        widget: 'Doctor',
      });
      updateState(message, 'date');
      updateExecutedActions('date');
      disableChatInput()
    }
  };

  const noAction = () => {
     
      const message = createChatBotMessage('If any enquiry contact 8807262725 or 0452-4051228 !')
      updateState(message);
      updateExecutedActions();
      disableChatInput();
    
  };

  const appointment = (doctorname) => {
    if (!executedActions.time) {
      const message = createChatBotMessage('Select your Appointment Date & Time', {
        widget: "Date"
      })
      updateState(message, 'time', doctorname);
      updateExecutedActions('time');
      disableChatInput()
    }
  };

  const timeaction = () => {
    if (!executedActions.medical) {
      const message = createChatBotMessage('Kindly select your Appointment time', {
        widget: "Time"
      })
      updateState(message, "medicalAffiliationaction");
      updateExecutedActions('medical');
      disableChatInput()

    }
  };


  const medicalAffiliationaction = (selectedTime) => {
    if (!executedActions.final
      ) {
    setCurrentAction('medicalAffiliationaction');
    const message = createChatBotMessage('Intent of Medical Visit ?', {});
    updateState(message, 'final',null, null, selectedTime);
    updateExecutedActions('final');
    enableChatInput();
    }
  };

  const finalaction = (patientName, doctorName, selectedDate, selectedTime) => {

    const message = createChatBotMessage(`Hi, ${patientName} Confirm your Appointment Details :
    DoctorName : ${doctorName},
    Appointment Date : ${selectedDate},
    Appointment Time : ${selectedTime}`
      , {
        widget: "Final"
      })
    updateState(message, "thankyou");
    disableChatInput()
  }

  const thankyouAction = () => {
    const message = createChatBotMessage('Thankyou for booking !',{
      widget:'ThumbGif'
    })
    updateState(message);
    disableChatInput()
  }

  const updateState = (message, checker, doctorName = null) => {
    
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      checker,
      userData: {
        ...prev.userData,
        doctorName: doctorName !== null ? doctorName : prev.userData.doctorName
      },
    }));
  };

  useEffect(() => {
    disableChatInput();
    return () => enableChatInput();
  }, []);

  return (
    <div>
      {children && React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            disableChatInput,
            initialAction,
            askRegisterAction,
            yesAction,
            noAction,
            appointment,
            Uhidselectaction,
            timeaction,
            // nameaction,
            genderaction,
            // ageaction,
            numberaction,
            medicalAffiliationaction,
            finalaction,
            thankyouAction,
            NameRegistration,
            FatherNameRegistration,
            DobRegistration,
            numberRegistrationaction,
            AadharRegistrationaction,
            AddressRegistrationaction,
            FinalRegistrationaction,
            bookedRegistrationaction,
            AlreadyExistaction,
            notRegisterMobile,
            RegisterMobile
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;

